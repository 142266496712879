import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-white.svg'
import location from '../img/location.svg'
import call from '../img/call.svg'
import email from '../img/email.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
         <div className="snippet">
          <div className="snippet-container">
              <div className="footer-brand">
                <Link to="/" title="Logo">
                  <img src={logo} alt="ZMLC" />
                </Link>
              </div>
              <p>ZM Land & Capital is a private UK property development company focussed on acquiring unconsented brownfield sites and securing planning permissions. 
              </p>
              <div className="snippet-end">
              
              </div>
            </div>
          </div>
          <div className="contacts">
            <h3>Contacts</h3>
            <div className="contacts-container">
              <div className="locations">
              <p>
                <span className="icon">
                <img src={location} alt="Location" />
                </span>
                1 Hyde Way<br />
                Welwyn Garden City<br />
                AL7 3BU
              </p>
              <p>
                <span className="icon">
                <img src={location} alt="Location" />
                </span>
                9 South Molton Street<br />
                Mayfair, London<br />
                W1K 5QH
              </p>
              
              </div>
              <div className="get-in-touch">
              <a href="mailto:info@zmlc.co.uk" className="emailer">
                <span className="icon">
                <img src={email} alt="Email" />
                </span>
                info@zmlc.co.uk
              </a>
              <a href="tel:+442075296800">
                <span className="icon">
                <img src={call} alt="Call" />
                </span>
                +44 (0)20 7529 6800
              </a>
              <p><span>©2021 ZML&C Limited. <br />
              All rights reserved.<br />
              <a href="https://wearefuture.agency">Website by Future™</a></span></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
