import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-white-rocket.svg'

const Navbar = class extends React.Component {

  render() {
    return (
      <nav
        className="navbar"
        role="navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" title="Logo">
              <img src={logo} alt="ZMLC" />
            </Link>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu`}
          >
            <div className="navbar-end">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/blog">
                Projects
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
